import { App } from 'vue'
import Sidebar from './Sidebar.vue'
import MainHeader from './MainHeader.vue'
import Checker from './Checker.vue'
import FiltersView from './FiltersView.vue'
import UserBar from './UserBar.vue'
import AuthProvider from './AuthProvider.vue'
import Breadcrumbs from './Breadcrumbs.vue'
import LanguageSelector from './LanguageSelector.vue'
import NotifyBar from './NotifyBar.vue'
import ThemeToggler from './ThemeToggler.vue'
import FullscreenToggler from './FullscreenToggler.vue'
import IconTooltip from './IconTooltip.vue'
import MenuTooltip from './MenuTooltip.vue'

const register = (app: App<Element>): void => {
  app
  .component('MenuTooltip', MenuTooltip)
  .component('IconTooltip', IconTooltip)
  .component('FullscreenToggler', FullscreenToggler)
  .component('ThemeToggler', ThemeToggler)
  .component('NotifyBar', NotifyBar)
  .component('LanguageSelector', LanguageSelector)
  .component('Breadcrumbs', Breadcrumbs)
  .component('AuthProvider', AuthProvider)
  .component('Sidebar', Sidebar)
  .component('UserBar', UserBar)
  .component('MainHeader', MainHeader)
  .component('Checker', Checker)
  .component('FiltersView', FiltersView)
}

export default {
  register,
}
