import { RouteRecordRaw } from 'vue-router';
import { route } from '@/router/utils'
import { allPermissions } from '@access/index'
import { accessPermission } from '@/permissions/permissions'

const {
  STAFF: {
    TAG: {
      VIEW
    },
  },
} = allPermissions

const List = () => import('./views/List.vue')

export const NOTIFICATIONS_LIST_PAGE_NAME = 'cabinet:notifications-list'
// URLs
export const NOTIFICATIONS_LIST_PAGE_URL = ''


export function createRoutes(options): RouteRecordRaw[] {
  return [
    route(NOTIFICATIONS_LIST_PAGE_URL, List, NOTIFICATIONS_LIST_PAGE_NAME, {
      meta: {
        breadcrumb: 'notify',
        ...accessPermission([VIEW], options),
        isMain: true
      }
    }),
  ]
}
