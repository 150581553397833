import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, withModifiers as _withModifiers, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "tt-notify-bar has-new" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "tt-notify-bar__notify-icon" }
const _hoisted_4 = {
  key: 0,
  class: "tt-notify-bar__content"
}
const _hoisted_5 = { class: "tt-notify-bar__content-title" }
const _hoisted_6 = { class: "tt-notify-bar__content-text" }
const _hoisted_7 = { class: "tt-notify-bar__messages-date" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "tt-notify-bar__message-time" }
const _hoisted_10 = { class: "tt-notify-bar__message-title" }
const _hoisted_11 = {
  key: 0,
  class: "tt-notify-bar__empty-list-message"
}
const _hoisted_12 = { class: "tt-notify-bar__content-footer" }
const _hoisted_13 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SPAN = _resolveComponent("SPAN")!
  const _component_loader = _resolveComponent("loader")!
  const _component_ui_click_outside = _resolveComponent("ui-click-outside")!
  const _component_sidebar_trigger = _resolveComponent("sidebar-trigger")!

  return (_openBlock(), _createBlock(_component_sidebar_trigger, { "input-ref": _ctx.$emit }, {
    default: _withCtx(({ sidebarIsOpen, sidebarToggle, sidebarClose }) => [
      _createVNode(_component_ui_click_outside, { do: sidebarClose }, {
        default: _withCtx(({ ref }) => [
          _createElementVNode("div", {
            class: "notify-click-wrapper",
            ref: ref
          }, [
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", {
                  class: "tt-notify-bar__icons-wrapper",
                  onClick: _withModifiers(($event: any) => ($setup.getMessagesList(sidebarToggle)), ["prevent"])
                }, [
                  _createElementVNode("div", _hoisted_3, [
                    (_openBlock(), _createBlock(_resolveDynamicComponent('i-notify'), { class: "theme-icon-stroke" }))
                  ])
                ], 8, _hoisted_2),
                _createVNode(_Transition, { name: "fade" }, {
                  default: _withCtx(() => [
                    sidebarIsOpen
                      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                          _createElementVNode("div", _hoisted_5, [
                            _createElementVNode("div", _hoisted_6, _toDisplayString($setup.$t('Notifications')), 1),
                            _createElementVNode("div", {
                              class: _normalizeClass(["tt-notify-bar__content-button", {'disabled' : !$setup.unreadCount}])
                            }, [
                              _createVNode(_component_SPAN, {
                                class: "hovered_underline",
                                onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => ($setup.readMessages('all')), ["prevent"]))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString($setup.$t('Read all')), 1)
                                ]),
                                _: 1
                              })
                            ], 2)
                          ]),
                          _createElementVNode("div", {
                            class: _normalizeClass(["tt-notify-bar__messages relative", {'main-height' : $setup.notificationsList.length}])
                          }, [
                            ($setup.load)
                              ? (_openBlock(), _createBlock(_component_loader, { key: 0 }))
                              : _createCommentVNode("", true),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.notificationsList, (item) => {
                              return (_openBlock(), _createElementBlock("div", {
                                key: item.date,
                                class: "tt-notify-bar__messages-wrapper"
                              }, [
                                _createElementVNode("div", _hoisted_7, _toDisplayString(item.date), 1),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.notifications, (message) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    key: message.id,
                                    class: _normalizeClass(["tt-notify-bar__message", message.flags[0].id !== 73232 ? 'readed' : 'pointer']),
                                    onClick: _withModifiers(($event: any) => (message.flags[0].id !== 73232 ? () => {} : $setup.readMessages(message.id)), ["prevent"])
                                  }, [
                                    _createElementVNode("div", _hoisted_9, _toDisplayString(message.createdAtTime), 1),
                                    _createElementVNode("div", _hoisted_10, _toDisplayString(message.message), 1)
                                  ], 10, _hoisted_8))
                                }), 128))
                              ]))
                            }), 128))
                          ], 2),
                          (!$setup.notificationsList.length)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString($setup.$t("You don't have any notifications yet")), 1))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", _hoisted_12, [
                            _createElementVNode("div", {
                              class: "tt-notify-bar__footer-button",
                              onClick: _withModifiers(sidebarToggle, ["prevent"])
                            }, null, 8, _hoisted_13)
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1024)
              ])
            ])
          ], 512)
        ]),
        _: 2
      }, 1032, ["do"])
    ]),
    _: 1
  }, 8, ["input-ref"]))
}