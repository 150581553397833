import { renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "cabinet-sidebar-menu-element__parent-count"
}
const _hoisted_2 = {
  key: 2,
  class: "cabinet-sidebar-menu-element__label"
}
const _hoisted_3 = {
  key: 3,
  class: "cabinet-sidebar-menu-element__counter-wrapper"
}
const _hoisted_4 = { class: "cabinet-sidebar-menu-element__counter" }
const _hoisted_5 = {
  key: 4,
  class: "cabinet-sidebar-menu-element__drop"
}
const _hoisted_6 = {
  key: 0,
  class: "cabinet-sidebar-menu-element__nested"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cabinet_sidebar_menu_link = _resolveComponent("cabinet-sidebar-menu-link")!
  const _component_cabinet_sidebar_menu_nested = _resolveComponent("cabinet-sidebar-menu-nested")!
  const _directive_on_click_outside = _resolveDirective("on-click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["cabinet-sidebar-menu-element", $setup.elementState]),
    onMouseover: _cache[0] || (_cache[0] = ($event: any) => ($props.condensed ? $setup.open() : null)),
    onMouseleave: _cache[1] || (_cache[1] = ($event: any) => ($props.condensed ? $setup.close() : null))
  }, [
    _createVNode(_component_cabinet_sidebar_menu_link, _mergeProps({
      class: "cabinet-sidebar-menu-element__link",
      href: $props.href,
      to: $props.to
    }, _ctx.$attrs, {
      disabled: $props.disabled,
      "has-child": !!($props.children && $props.children.length),
      onClicked: $setup.toggle,
      class: { 'is-current': $setup.currentPath == $props.href }
    }), {
      default: _withCtx(() => [
        (_ctx.$slots.icon || $props.icon)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: "cabinet-sidebar-menu-element__icon",
              onClick: $setup.toggle
            }, [
              _renderSlot(_ctx.$slots, "icon", { icon: $props.icon }, () => [
                (_openBlock(), _createBlock(_resolveDynamicComponent($props.icon), { size: ['md'] }))
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.$attrs.parentCount)
          ? (_openBlock(), _createElementBlock("span", _hoisted_1))
          : _createCommentVNode("", true),
        (_ctx.$slots.label || $props.label)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
              _renderSlot(_ctx.$slots, "label", { label: $props.label }, () => [
                _createTextVNode(_toDisplayString($props.label), 1)
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.$slots.counter || $props.counter)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
              _createElementVNode("span", _hoisted_4, [
                _renderSlot(_ctx.$slots, "counter", { counter: $props.counter }, () => [
                  _createTextVNode(_toDisplayString($props.counter), 1)
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        ($props.children && $props.children.length && $props.drop && !$props.condensed)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _renderSlot(_ctx.$slots, "drop-icon", {}, () => [
                _createElementVNode("div", {
                  class: _normalizeClass(["cabinet-sidebar-menu-element__drop-icon", { 'is-active': !$setup.opened }])
                }, [
                  (_openBlock(), _createBlock(_resolveDynamicComponent("i-arrow-down-light")))
                ], 2)
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 3
    }, 16, ["href", "to", "disabled", "has-child", "class"]),
    ($props.children)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _renderSlot(_ctx.$slots, "nested", {}, () => [
            _createVNode(_component_cabinet_sidebar_menu_nested, {
              items: $props.children,
              condensed: $props.condensed,
              level: 1,
              "nested-component": 'cabinet-sidebar-menu-nested'
            }, null, 8, ["items", "condensed"])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 34)), [
    [_directive_on_click_outside, $setup.outside]
  ])
}