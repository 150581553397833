import type { Router } from 'vue-router'
import { defineStore } from '@/modules/pinia-hot'

import {
  notificationsCountResource,
} from '@/services/notifications.service'

const id = 'notifications'

export const useNotifications = defineStore(id, {
  state: () => ({
    unreadCount: 0,
  }),
  getters: {
  },
  actions: {
    async getUnread() {
      const { data: { item } } = await notificationsCountResource.execute({})
      this.unreadCount = item.conversionProtocols
    },
  },
})
